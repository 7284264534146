<template>
  <div class="container app-content pt-4">
    <!-- Page Header -->
    <PageHeader title="Promotions" />

    <Error v-if="error" :error="error" />
    <Loading v-else-if="isLoading" />
    <template v-else>
      <PromotionsNavigation
        @searchQuery="searchQuery"
        @showPromotionCreateForm="showPromotionCreateForm = true"
      />
      <div class="row">
        <div class="col-12">
          <PaginationResults
            title-suffix="promotions"
            :total="meta.pagination.total"
            :current-page="pageNum"
            :total-pages="meta.pagination.total_pages"
            :per-page="perPage"
            @searchQuery="searchQuery"
          />
        </div>
        <div class="col-3 m-auto" v-if="!hasPromotions">
          <EmptyCard title="There are no promotions" />
        </div>
        <template v-else>
          <div
            class="col-sm-6 col-md-4 d-flex"
            v-for="promotion in promotions"
            :key="`promotion-card-${promotion.promotion_id}`"
          >
            <PromotionCard :promotion="promotion" :campaign="promotion.campaign" />
          </div>
        </template>
      </div>
      <Pagination
        :total="meta.pagination.total"
        :current-page="pageNum"
        :total-pages="meta.pagination.total_pages"
        :per-page="perPage"
        @pageFirst="pageFirst"
        @pageLast="pageLast"
        @pageNext="pageNext"
        @pagePrevious="pagePrevious"
        @toPage="toPage"
        @setPerPage="setPerPage"
      />
    </template>

    <Modal id="modal-promotion-create">
      <template v-slot:title>Create Promotion</template>

      <template v-slot:body>
        <PromotionForm
          v-if="showPromotionCreateForm"
          modal="#modal-promotion-create"
          @complete="fetchData()"
          @close="showPromotionCreateForm = false"
        />
      </template>
    </Modal>
  </div>
</template>

<script>
import hasTimeDate from 'mixins/hasTimeDate';
import paginationFunctions from 'mixins/paginationFunctions';
import EmptyCard from 'components/status-cards/EmptyCard';
import Error from 'components/Error';
import Loading from 'components/Loading';
import Modal from 'components/Modal';
import PageHeader from 'components/PageHeader';
import Pagination from 'components/Pagination';
import PaginationResults from 'components/PaginationResults';
import PromotionCard from 'components/promotions/PromotionCard';
import PromotionForm from 'components/forms/PromotionForm';
import PromotionsNavigation from 'components/promotions/PromotionsNavigation';

export default {
  name: 'PromotionsIndex',
  mixins: [hasTimeDate, paginationFunctions],
  components: {
    PromotionCard,
    PromotionForm,
    PromotionsNavigation,
    EmptyCard,
    Error,
    Loading,
    Modal,
    PageHeader,
    Pagination,
    PaginationResults,
  },
  props: {
    campaignId: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      error: null,
      isLoading: true,
      pageNum: Number(this.$route.query.page) || 1,
      perPage: Number(this.$route.query.per_page) || 12,
      showPromotionCreateForm: false,
    };
  },
  computed: {
    promotions() {
      return this.$store.getters['promotions/getAll'];
    },
    hasPromotions() {
      return this.promotions.length > 0;
    },
    meta() {
      return this.$store.getters['promotions/getAllMeta'];
    },
    q() {
      return this.$store.getters['search/getKeywords'];
    },
    dates() {
      return this.$store.getters['search/getRange'];
    },
  },
  created() {
    // fetch the data when the view is created and the data is
    // already being observed
    this.fetchData();
  },
  unmounted() {
    // console.log('PromotionsIndex unmounted()');
    this.$store.commit('search/clearSearch');
  },
  methods: {
    fetchData(params) {
      this.error = null;
      this.isLoading = true;
      // hide the Promotion Edit Form if visible
      this.showPromotionCreateForm = false;

      const defaultParams = {
        page: this.pageNum,
        per_page: this.perPage,
        with: 'statistics;campaign;intentions',
      };

      const payload = {
        params: Object.assign(defaultParams, params || {}),
      };

      if (this.q) {
        payload.params.search = this.q;
      }

      // Check for limits imposed by a campaign, promotion, or manually set date range
      const dateLimits = this.getDateLimits();
      if (dateLimits) {
        payload.params.timeslice = dateLimits;
      }

      // console.log('PromotionsIndex methods fetchData() :payload', payload);

      this.$store
        .dispatch('promotions/getAll', payload)
        .catch((error) => {
          this.error = this.$errorProcessor(error);
        })
        .then(() => {
          this.isLoading = false;
        });
    },
    compare(value1, value2, key) {
      return key === 'creation' ? true : undefined;
    },
    getDateLimits() {
      // Default to no limits;
      let limits = false;
      let start = false;
      let end = false;

      if (this.dates) {
        // Start with the most specific, manually set dates in the search bar. This takes precedence over all others
        ({ start, end } = this.dates);
      }

      if (start && end) {
        // If we have values for the date range, set them and pass back to the caller
        limits = `created_at;${this.formatISODate(start)}|${this.formatISODate(end)}`;
      }

      return limits;
    },
    searchQuery() {
      // console.log('PromotionsIndex methods searchQuery()');
      this.pageNum = 1;

      this.fetchData();
    },
  },
};
</script>

<style scoped lang="scss">
.card-group {
  .card {
    flex-basis: 25%;
    flex-grow: 0;
  }
}
</style>
